import React from 'react';
import './Banner.css';
import logo from "../../Assets/images/bnrlogo.png"
import video2 from '../../Assets/images/bnrvid.mp4';

const Banner = () => {
  return (
    <section className="section hero w-background-video w-background-video-atom">
      <video autoPlay loop muted playsInline className="w-background-video">
        <source src={video2} type="video/mp4" />
        <source src={video2} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <div className="container hero">
        <div className="hero-div">
          <img className="hero-logo" alt="hero-logo" src={logo} />
          <h1 className="hero-heading">

            <span className="hero-connecting"> Welcome to Ape.Community </span>
            <span className="hero-decentralized">– Your Gateway to</span>
            <span className="hero-communities">Decentralized Earning!</span>
          </h1>
          <h2 className="secondary-heading">
            Experience the future of finance with fast, secure, and transparent transactions on the Polygon blockchain. Join us to unlock the power of decentralized earnings and smart contract technology.
          </h2>

          <div className='bnrbtn d-flex mt-5 '>
            <a href="https://users.ape.community" target=''><button class="am_btn mb-3  px-2"><i></i><i></i><span> Login</span></button></a>
            <a href="https://users.ape.community/register" target=''><button class="am_btn  px-2"><i></i><i></i><span>Register</span></button></a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
