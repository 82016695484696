import React from "react";
import "../Footer/Footer.css";

const Footer = () => {
  return (
    <footer className="footer bg-black">
      <div className="container px-md-26 w-container">
        <div className="footer_inner_row">
          <a
            href="/"
            aria-current="page"
            className="footer_logo_wrapper w-inline-block w--current"
          >

          </a>

        </div>
        <div className="footer_seperator">
          <div className="footer-seperator-line" />
          <img
            src="https://assets.website-files.com/641b3680a288f7165dd8bcb2/641b3680a288f71937d8bce9_gradient_star.svg"
            loading="lazy"
            alt="gradient star
  "
            className="footer_seperator_star"
          />
        </div>
        <div className="footer_copyright_wrapper">
          <div className="footer_copyright_text">
            © 2024 Ape.Community. All rights reserved
          </div>
          <div className="community_icon_wrapper">
            <a href="https://t.me/apecommunitynft" target="_blank" className="icon_wrapper w-inline-block" >
               {/* <img
                src="https://assets.website-files.com/641b3680a288f7165dd8bcb2/641b3680a288f72c7ed8bceb_discord.svg"
                loading="lazy"
                alt="discord blue green icon"
                className="community_icon"
              /> */}
              <svg xmlns="http://www.w3.org/2000/svg" aria-label="Telegram" viewBox="0 0 512 512" id="telegram"><rect width="512" height="512" fill="#37aee2" rx="15%"></rect><path fill="#c8daea" d="M199 404c-11 0-10-4-13-14l-32-105 245-144"></path><path fill="#a9c9dd" d="M199 404c7 0 11-4 16-8l45-43-56-34"></path><path fill="#f6fbfe" d="M204 319l135 99c14 9 26 4 30-14l55-258c5-22-9-32-24-25L79 245c-21 8-21 21-4 26l83 26 190-121c9-5 17-3 11 4"></path></svg>
            </a>
            {/* <a
              data-w-id="409c4157-14cf-4f01-1b19-73e4de44d783"

              target="_blank"
              className="icon_wrapper w-inline-block"
            >
              <img
                src="https://assets.website-files.com/641b3680a288f7165dd8bcb2/641b3680a288f70829d8bcec_twitter-square%201.svg"
                loading="lazy"
                alt="twitter gradient icon"
                className="community_icon"
              />
            </a> */}
            {/* <a
              data-w-id="409c4157-14cf-4f01-1b19-73e4de44d781"

              target="_blank"
              className="icon_wrapper w-inline-block"
            >
              <img
                src="https://assets.website-files.com/641b3680a288f7165dd8bcb2/641b3680a288f72c7ed8bceb_discord.svg"
                loading="lazy"
                alt="discord blue green icon"
                className="community_icon"
              />
            </a> */}
            {/* <a
              data-w-id="409c4157-14cf-4f01-1b19-73e4de44d783"

              target="_blank"
              className="icon_wrapper w-inline-block"
            >
              <img
                src="https://assets.website-files.com/641b3680a288f7165dd8bcb2/641b3680a288f70829d8bcec_twitter-square%201.svg"
                loading="lazy"
                alt="twitter gradient icon"
                className="community_icon"
              />
            </a> */}
            {/* <a
              data-w-id="409c4157-14cf-4f01-1b19-73e4de44d781"

              target="_blank"
              className="icon_wrapper w-inline-block"
            >
              <img
                src="https://assets.website-files.com/641b3680a288f7165dd8bcb2/641b3680a288f72c7ed8bceb_discord.svg"
                loading="lazy"
                alt="discord blue green icon"
                className="community_icon"
              />
            </a> */}
          </div>
        </div>
      </div>
    </footer>

  );
};
export default Footer;