import React from "react";
import { CContainer, CRow, CCol } from "@coreui/react";
// import forex4 from "../assets/Image/forex4.jpg";
import nft1 from '../../Assets/images/nfts/nfts/nft1.gif'
import nft2 from '../../Assets/images/nfts/nfts/nft2.gif'
import nft3 from '../../Assets/images/nfts/nfts/nft3.gif'
import nft4 from '../../Assets/images/nfts/nfts/nft4.gif'
import nft5 from '../../Assets/images/nfts/nfts/nft5.gif'
import nft6 from '../../Assets/images/nfts/nfts/nft6.gif'
import nft7 from '../../Assets/images/nfts/nfts/nft7.gif'
import nft8 from '../../Assets/images/nfts/nfts/nft8.gif'
import nft9 from '../../Assets/images/nfts/nfts/nft9.gif'
import nft10 from '../../Assets/images/nfts/nfts/nft10.gif'
import nft11 from '../../Assets/images/nfts/nfts/nft11.gif'
import nft12 from '../../Assets/images/nfts/nfts/nft12.gif'
import nft13 from '../../Assets/images/nfts/nfts/nft13.gif'
import nft14 from '../../Assets/images/nfts/nfts/nft14.gif'
import nft15 from '../../Assets/images/nfts/nfts/nft15.gif'


import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import '../Nftsec/Nft.css'





const Nftsection = () => {
  const carouselRef = React.useRef();
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };
  const items = [
    <div className="item">

      <div className="plancard">
        <img src={nft1} alt="" />
        <h3>#1 Smoky Cyber</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft2} alt="" />
        <h3>#2 Cyber Scape</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft3} alt="" />
        <h3>#3 Alpha Aura</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft4} alt="" />
        <h3>#4 Cloudy Ape</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft5} alt="" />
        <h3>#5 Nova Nebula</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft6} alt="" />
        <h3>#6 Quite Mamba</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft7} alt="" />
        <h3>#7 Gold Tonic</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft8} alt="" />
        <h3>#8 Arrowed Knight</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft9} alt="" />
        <h3>#9 Lazy Samurai</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft10} alt="" />
        <h3>#10 Future Fusion</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft11} alt="" />
        <h3>#11 Vortex Vision</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft12} alt="" />
        <h3>#12 Techno Titan</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft13} alt="" />
        <h3>#13 Hyper Vault</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft14} alt="" />
        <h3>#14 Killer Kong</h3>

      </div>
    </div>,
    <div className="item">

      <div className="plancard">
        <img src={nft15} alt="" />
        <h3>#15 Neon Nexus</h3>

      </div>
    </div>

  ];
  return (
    <section className="plan-area expl" id="planopportunity">


      <div className="container  ">


        <div className="plantittlesection">
          <h2 className=" section-explore__title ">Ape <span className='meta'>Nft
          </span> </h2>
          <div className="planbutton">
            <div class="button-prev" onClick={() => carouselRef.current.slidePrev()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="icon"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                />
              </svg>
            </div>
            <div class="button-next" onClick={() => carouselRef.current.slideNext()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="icon"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="plancardsection">
          <AliceCarousel
            ref={carouselRef}
            mouseTracking
            items={items}
            responsive={responsive}
            controlsStrategy="responsive"
            dotsDisabled={true}
            buttonsDisabled={false}
            keyboardNavigation={false}
            infinite
          />
        </div>


      </div>
    </section>
  );
};

export default Nftsection