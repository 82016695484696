import React, {
  useState,
  useEffect,
  useRef,
} from "react";

import Banner from "../../Components/Banner/Banner";

import Advantages from "../../Components/Advantages/Advantages";
// import Roadmap from "../../Components/Roadmap/Roadmap";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import About from "../../Components/About/About";
import Explore from "../../Components/Explore/Explore";
// import Tokenomics from "../../Components/Tokenomics/Tokenomics";
import Faqsec from "../../Components/Faq/Faq";
import Nftsection from "../../Components/Nftsec/Nftsection";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const videoRef = useRef(null);
  const timeline = useRef(gsap.timeline({ paused: true }));
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    timeline.current.to(videoRef.current, {
      opacity: 1,
      duration: 1,

    });
  }, []);

  const handleScroll = () => {

    timeline.current.play();
  };

  const handleHover = () => {

    setHovered(true);
    timeline.current.play();
  };
  return (
    <>
      <Banner />
      <About />
      <Explore />
      <Advantages />
      <Nftsection />
      {/* <Tokenomics /> */}
      {/* <Roadmap /> */}
      <Faqsec />






    </>
  );
};

export default Home;
