import React from 'react';
import "../Advantages/Advantages.css"

const AdvantagesSection = () => {
  return (
    <section id="Advantages" className="section-advantages rotateGradient ">
     
      <div className="section-advantages__container container mx-auto relative  advgradient">
        
        
        <h2 className=" section-advantages__title">Advan
        <span className='meta'>tages
              </span>  </h2>
        <div className="section-advantages__image "  />
        <ul className="section-advantages__list grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <AdvantageItem
            title="Instant Distribution:"
            text=" Receive immediate rewards directly to your wallet – no waiting, no hassle."
          />
          <AdvantageItem
            title="Autonomous and Immutable"
            text=" Fully powered by smart contracts, reducing human error and ensuring immutable conditions."
          />
          <AdvantageItem
            title="Transparent and Open"
            text="Complete visibility with an open smart contract code and a transparent transaction history"
          />
          <AdvantageItem
            title="Fully Decentralized"
            text="No central authority or hidden fees; Ape.Community is a platform of the people, by the people, for the people."
          />
          <AdvantageItem
            title="Economic Efficiency:"
            text="Enjoy faster, more cost-effective transactions than traditional blockchain networks."
          />
        </ul>
       
      </div>
    </section>
  );
};

const AdvantageItem = ({ title, text }) => {
  return (
    <li className="section-advantages__item cards__item col-span-1 sm:col-span-1 lg:col-span-1">
      <p className="section-advantages__item-title cards__title text-xl font-semibold mb-2">{title}</p>
      <p className="section-advantages__item-text cards__text">{text}</p>
    </li>
  );
};

export default AdvantagesSection;
