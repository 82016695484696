import React from 'react'
import { Outlet } from 'react-router'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import 'react-use-smooth-scroll/dist/index.css'

const Layout = () => {
    return (
        <>
            <Header />
            <div className='main'>
                <Outlet />
            </div>

            <Footer />
        </>
    )
}

export default Layout