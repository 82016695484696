import React from 'react';
import "../About/About.css"
import abtleft from '../../Assets/images/Jarsy.png'
import ape from "../../Assets/images/faqAssets.webp"
import nft1 from "../../Assets/images/nft2.jpg"
import nft2 from "../../Assets/images/nft3.jpg"
import nft3 from "../../Assets/images/nft5.jpg"
import nft4 from "../../Assets/images/nft6.jpg"

const About = () => {
  return (
    <section id="About" className="description-section abtgradient rotateGradient">
      <div className="container mx-auto abtgradient2">
        <div className="absolute left-0 top-[0%] md:top-[100px]">
          <img
            alt="Rectangle-Left-Image"
            loading="lazy"
            width={0}
            height={0}
            decoding="async"
            data-nimg={1}
            className="h-[200px] w-[200px] md:h-[287px] md:w-[290px] abtimg"
            style={{ color: "transparent" }}
            src={abtleft}
          />
        </div>
        <div className="flex row align-items-center justify-between items-center">
          <div className=" col-12 col-lg-6">
            <h2 className="text-2xl font-bold">
              About  <span className='meta'>Ape.Community
              </span>
            </h2>
            <div className="description-section__text">
              <p>
                Ape.Community is a trailblazing decentralized platform on the Polygon network, designed for users seeking a transparent,
                efficient, and equitable way to earn in the cryptocurrency realm.
              </p>
              <p>
                Our platform is powered by cutting-edge smart contracts, ensuring fast, low-cost transactions and an autonomous ecosystem free from central control. Ape.
                Fund is committed to offering a fair, economically sound system with no deadlines for slots, a limited number of places, and an unlimited number of repeats.
              </p>
            </div>
          </div>
          <div className=" col-12 col-lg-6 loginRegisterBox">
            <div className=" rotateGradientAb">
              <div className="logRegInner">
                <div className="boxIn1"></div>
                <div className="boxIn2"></div>
                <div className="boxIn3"></div>
                <div className='buttonBox btn1'><img src={nft1} alt="" /></div>
                <div className='buttonBox btn2'><img src={nft2} alt="" /></div>
                <div className='buttonBox btn3'><img src={nft3} alt="" /></div>
                <div className='buttonBox btn4'><img src={nft4} alt="" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
