import React from 'react';
import '../Explore/Explore.css'

const Explore = () => {
  return (
    <section id="Explore" className='explore expl'>
      <div className="container explgradient ">
        <div className="navigation_line" />
        <h2 className=" section-explore__title ">Exploring <span className='meta'>Ape.Community
        </span> </h2>
        <ul className="innovations_list" style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
          <li
            className="innovation">
            <a
              data-v-ef57dc4d=""

              target="_blank"
            >
              <strong data-v-ef57dc4d="">
                Delve into the world of Ape.Community and discover a network that redefines decentralized earning.
              </strong>

            </a>

          </li>
          <li
            className="innovation2">
            <a
              data-v-ef57dc4d=""

              target="_blank"
            >
              <strong data-v-ef57dc4d="">
                Our platform harnesses the collective wisdom of our community, offering instant reward distribution and an open, transparent ledger.
              </strong>

            </a>
          </li>
          <li
            className="innovation3">
            <a
              data-v-ef57dc4d=""

              target="_blank"
            >
              <strong data-v-ef57dc4d="">
                With Ape.Community, everything is transparent, fast, and always on the go, ensuring a seamless experience for all our users.
              </strong>

            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Explore;
