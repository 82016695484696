import React, { useEffect } from "react";
import imglogo from "../../Assets/images/comp (1).gif";


import { useState } from "react";
import "../Header/header.css"



const Header = () => {

  const [isOpen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeNav = () => {
    setIsOpen(false);
    document.body.style.overflow = "visible";
  };
  return (
    <>
      <section>

        <header id="head" className="head" data-page="1">
          <div className="container mx-auto  px-4">
            <div className="headCon"
              data-aos="fade-down"
              data-aos-duration="1000">
              <a className="logo" href="#">
                <img className=" " src={imglogo} alt="" />
              </a>

              <div className="navBoxList">
                <a href="#About" class="link">
                  <span class="mask">
                    <div class="link-container">
                      <span class="link-title1 title">About </span>
                      <span class="link-title2 title">About </span>
                    </div>
                  </span>
                </a>
                <a href="#Explore" class="link">
                  <span class="mask">
                    <div class="link-container">
                      <span class="link-title1 title">Explore</span>
                      <span class="link-title2 title">Explore</span>
                    </div>
                  </span>
                </a>
                <a href="#Advantages" class="link">
                  <span class="mask">
                    <div class="link-container">
                      <span class="link-title1 title">Advantages</span>
                      <span class="link-title2 title">Advantages</span>
                    </div>
                  </span>
                </a>
                {/* <a href="#Tokenomics" class="link">
                  <span class="mask">
                    <div class="link-container">
                      <span class="link-title1 title ">Tokenomics</span>
                      <span class="link-title2 title">Tokenomics</span>
                    </div>
                  </span>
                </a> */}
                {/* <a href="#Roadmap" class="link">
                  <span class="mask">
                    <div class="link-container">
                      <span class="link-title1 title">Roadmap</span>
                      <span class="link-title2 title">Roadmap</span>
                    </div>
                  </span>
                </a> */}

                <a href="#Faq" class="link">
                  <span class="mask">
                    <div class="link-container">
                      <span class="link-title1 title ">Faq</span>
                      <span class="link-title2 title">Faq</span>
                    </div>
                  </span>
                </a>
              </div>
              <div className="resnav z-40">
                <div id="mySidenav" className={`sidenav ${isOpen ? "open" : ""}`}>
                  <div className="d-flex">
                    <a className="logo lgmb" href="#">
                      <img className=" mblogo " src={imglogo} alt="" />
                    </a>
                    <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
                      &times;
                    </a>
                  </div>
                  <a className="abt" href="#About" onClick={closeNav}>
                    About
                  </a>
                  <a href="#Explore" onClick={closeNav}>
                    Explore
                  </a>
                  <a href="#Advantages" onClick={closeNav}>
                    Advantages
                  </a>
                  {/* <a href="#Tokenomics" onClick={closeNav}>
                    Tokenomics
                  </a>
                  <a href="#Roadmap" onClick={closeNav}>
                    Roadmap
                  </a> */}
                  <a href="#Faq" onClick={closeNav}>
                    Faq
                  </a>

                </div>
                <span
                  style={{ fontSize: "30px", cursor: "pointer" }}
                  onClick={openNav}
                >
                  &#9776;
                </span>
              </div>
            </div>


          </div>
        </header>

      </section>
    </>
  )
}
export default Header