import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from "../../Assets/images/faqAssets.webp";
import img2 from "../../Assets/images/653be1cc9a65715902e0f58e_Mask group.webp";
import img4 from "../../Assets/images/653be1cc2f7b4511e2f98720_Group 648-p-1600.webp";
import img3 from "../../Assets/images/653be1cc5084e0e97e3999cb_Group 647.webp";
import '../Faq/Faq.css';

const Faqsec = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, 
  };

  return (
    <section id="Faq" className="home_why faqgradient rotateGradient ">
      <div className="container-large container">
      <h2 className=" section-explore__title ">Frequently Asked <span className='meta'> Questions
              </span></h2>
        <Slider {...sliderSettings}>
          <div className="cta_slider_content">  
            <h2 className="heading_standard mt-5">1. What is Ape.Community?</h2>
            <p className="home_why_card_paragraph mt-3">
            Ape.Community is a decentralized networking platform on the Polygon chain, leveraging smart contracts to provide a secure and efficient earning environment.
            </p>
          
            <div className="cta_slider_image">
              <img src={img1} alt="" className="image_contain fit-bottom" />
            </div>
          </div>
          <div className="cta_slider_content">
            <h2 className="heading_standard">2. How does Ape.Community ensure fairness and transparency?</h2>
            <p className="home_why_card_paragraph">
            Ape.Community's smart contract code is open for public viewing, ensuring transparent operations and a clear view of all transactions.
            </p>
         
            <div className="cta_slider_image">
              <img src={img2} alt="" className="image_contain fit-bottom" />
            </div>
          </div>
          <div className="cta_slider_content">
            <h2 className="heading_standard">3. Are there any hidden fees with Ape.Community?</h2>
            <p className="home_why_card_paragraph">
            No, Ape.Community operates with complete transparency and does not charge any hidden fees. The contract balance is always zero, reflecting our commitment to fairness.
            </p>
          
            <div className="cta_slider_image">
              <img src={img3} alt="" className="image_contain fit-bottom" />
            </div>
          </div>
          <div className="cta_slider_content">
            <h2 className="heading_standard">4. How does the reward distribution work on Ape.Community?</h2>
            <p className="home_why_card_paragraph">
            Rewards on Ape.Community are distributed instantly and directly into users' decentralized wallets, eliminating the need for manual claims.
            </p>
        
            <div className="cta_slider_image">
              <img src={img4} alt="" className="image_contain fit-bottom" />
            </div>
          </div>
          <div className="cta_slider_content">
            <h2 className="heading_standard">5. What makes Ape.Community different from other decentralized platforms?</h2>
            <p className="home_why_card_paragraph">
            Ape.Community stands out due to its immediate reward system, autonomous smart contract technology, and commitment to a fully transparent and equitable platform for all users.
            </p>
           
            <div className="cta_slider_image">
              <img src={img1} alt="" className="image_contain fit-bottom" />
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Faqsec;
